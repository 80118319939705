import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ThemeProvider} from 'styled-components';
import {Layout} from './layout/layout';
import {fixInputsWithNumbers} from './helpers/textInputsHelper';
import {fetchApplication} from './store/client';
import {DisabledWidgetWarn} from './components/disabledWidgetWarn/DisabledWidgetWarn';
import {theme} from './layout/theme';
import './helpers/i18n';
import './App.css';

const App = () => {
  const queryParameters = new URLSearchParams(location.search);
  const pid = queryParameters.get('product_id');
  const vid = queryParameters.get('product_variant_id');
  const applicationKey = queryParameters.get('application_key');
  const cartKey = queryParameters.get('cart_item_key');
  const apiConfig = {headers: {'Application-Key': applicationKey}};
  const {data: application, loading} = useSelector(
    state => state.client.application,
  );
  const dispatch = useDispatch();

  const retrieveClientApplication = async () => {
    dispatch(fetchApplication({config: apiConfig}));
  };

  const getTheme = application => {
    const logoObj = (application?.settings || []).find(
      i => i?.name === 'integration-api.design-widget.logo',
    );
    const mainColorObj = (application?.settings || []).find(
      i => i?.name === 'integration-api.design-widget.main-color',
    );
    return {
      ...theme,
      ...(logoObj?.value ? {appLogo: logoObj?.value} : {}),
      ...(mainColorObj?.value ? {primaryColor: mainColorObj?.value} : {}),
    };
  };

  const isWidgetEnable = application => {
    return (
      (application?.settings || []).find(
        i => i?.name === 'integration-api.design-widget.enable',
      )?.value === 'true'
    );
  };

  useEffect(() => {
    fixInputsWithNumbers();
    retrieveClientApplication();
  }, []);

  // Text URL:
  // ?product_id=16875&product_variant_id=16876&cart_item_key=dc9b0c088d2fc8fa09b15d8f602f82f8&application_key=4c4626f3-a31e-46cd-9912-698af8ab9e78

  return application && isWidgetEnable(application) ? (
    <ThemeProvider theme={getTheme(application)}>
      <Layout pid={pid} vid={vid} cartKey={cartKey} apiConfig={apiConfig} />
    </ThemeProvider>
  ) : !loading ? (
    <DisabledWidgetWarn
      text={'Tixxio Design Widget is not enabled.'}
      onClose={() => window.parent.postMessage('closeButtonClicked', '*')}
    />
  ) : null;
};

export default App;
