import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Item = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${props =>
      props?.$isSelected
        ? props.theme.primaryColor
        : props.theme.borderLightColor};
  //background-color: ${props => props?.$isSelected ? '#fff' : props.theme.borderLightColor};
  width: 80px;
  height: 55px;
`;

export const Icon = styled.img`
  max-height: 70px;
  max-width: 35px;
`;

export const Text = styled.div`
  font-size: 13px;
`;
