import React, {useRef, useEffect, useState} from 'react';
import {Layer, Stage, Image, Group} from 'react-konva';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRotate} from '@fortawesome/free-solid-svg-icons';
import {ButtonWrapper, ModalWrapper} from '../index';
import * as S from './styled';

const {innerWidth: windowWidth, innerHeight: windowHeight} = window;
const windowMinSize =
  Math.min(windowWidth, windowHeight) < 400
    ? Math.min(windowWidth, windowHeight) - 100
    : 400;
const canvas = {width: windowMinSize, height: windowMinSize};
const visibleCutArea = {
  width: windowMinSize,
  height: windowMinSize,
  radius: windowMinSize / 2,
};
const rotateList = [0, 90, 180, 270];
const minMaxZoom = 10;

export const SetupImage = props => {
  const {popupRef, object, image: imageParam, onClose, onResult} = props;
  const {t} = useTranslation(['SetupImage']);
  const theme = useTheme();
  const [shapeChange, setShapeChange] = useState(null);
  const [scale, setScale] = useState(1);
  const [zoomValue, setZoomValue] = useState(
    object?.params?.fillPatternScaleX || 1,
  );
  const [maxZoomValue, setMaxZoomValue] = useState(0);
  const [rotateValue, setRotateValue] = useState(0);
  const [rotateValueIndex, setRotateValueIndex] = useState(0);
  const stageRef = useRef(null);
  const cropShapeRef = useRef(null);
  const imageRef = useRef(null);
  const canvasMinSize = Math.min(canvas.width, canvas.height);
  let {width: oWidth, height: oHeight, radius: oRadius} = object.params;
  if (oRadius !== undefined && oWidth === undefined && oHeight === undefined) {
    oWidth = oRadius * 2;
    oHeight = oRadius * 2;
  }
  const radiusScale = visibleCutArea.radius / oRadius;
  const rectScale =
    Math.min(visibleCutArea.width, visibleCutArea.height) /
    Math.max(oWidth, oHeight);
  const strokeWidth = oRadius * 4 || Math.max(oWidth, oHeight) * 4;
  const imageRotation = rotateList[rotateValueIndex] + rotateValue;
  const canvasOffsetX = canvas.width / 2;
  const canvasOffsetY = canvas.height / 2;

  const getCropShapePos = () => {
    if (oWidth && oHeight && oRadius === undefined) {
      const objectMaxSize = Math.max(oWidth, oHeight);
      if (objectMaxSize < canvasMinSize) {
        return {
          x: canvas.width / 2 - oWidth / 2,
          y: canvas.height / 2 - oHeight / 2,
        };
      }
      return {
        x: oWidth < oHeight ? (oHeight - oWidth) / 2 : 0,
        y: oWidth > oHeight ? (oWidth - oHeight) / 2 : 0,
      };
    }
    if (oRadius) {
      if (oRadius * 2 < canvasMinSize) {
        return {
          x: canvas.width / 2,
          y: canvas.height / 2,
        };
      }
      return {
        x: oRadius,
        y: oRadius,
      };
    }
    return {
      x: canvas.width / 2,
      y: canvas.height / 2,
    };
  };

  const getMaskShapePos = cropShapePos => {
    const x = oRadius
      ? cropShapePos?.x
      : cropShapePos?.x - (strokeWidth * rectScale) / 2;
    const y = oRadius
      ? cropShapePos?.y
      : cropShapePos?.y - (strokeWidth * rectScale) / 2;
    return {x, y};
  };

  const cropShapePos = getCropShapePos();
  const maskShapePos = getMaskShapePos(cropShapePos);

  const onDragEnd = e => {
    setShapeChange({
      action: 'onDragEnd',
      params: {
        ...shapeChange?.params,
        x: e.target.x(),
        y: e.target.y(),
      },
    });
  };

  const isWithoutFillPatternData = params => {
    return (
      params.fillPatternX === undefined &&
      params.fillPatternY === undefined &&
      params.fillPatternOffsetX === undefined &&
      params.fillPatternOffsetY === undefined
    );
  };

  const isWithOffsetFillPatternData = params => {
    return (
      params.fillPatternX === undefined &&
      params.fillPatternY === undefined &&
      params.fillPatternOffsetX !== undefined &&
      params.fillPatternOffsetY !== undefined
    );
  };

  const setInitialValues = scale => {
    const setImageValues = (imageWidth, imageHeight) => {
      const initialScale =
        Math.min(oWidth, oHeight) / Math.min(imageWidth, imageHeight);
      const zoomValue = object?.params?.fillPatternScaleX || initialScale || 1;
      const offsetX = object?.params?.fillPatternOffsetX || 0;
      const offsetY = object?.params?.fillPatternOffsetY || 0;
      let circleX = cropShapePos.x - offsetX + canvasOffsetX;
      let circleY = cropShapePos.y - offsetY + canvasOffsetY;
      let rectX = canvasOffsetX - offsetX;
      let rectY = canvasOffsetY - offsetY;
      if (
        isWithoutFillPatternData(object?.params) ||
        isWithOffsetFillPatternData(object?.params)
      ) {
        const initialPosX =
          canvasOffsetX / scale - cropShapePos.x - imageWidth / 2;
        const initialPosY =
          canvasOffsetY / scale - cropShapePos.y - imageHeight / 2;
        // cx & cy used for compatibility with old type canvas
        const cx =
          object?.params?.fillPatternX ||
          (!object?.params?.fillPatternOffsetX ? initialPosX : 0);
        const cy =
          object?.params?.fillPatternY ||
          (!object?.params?.fillPatternOffsetY ? initialPosY : 0);
        circleX = cropShapePos.x - oRadius / zoomValue + canvasOffsetX;
        circleY = cropShapePos.y - oRadius / zoomValue + canvasOffsetY;
        rectX =
          cropShapePos.x + cx - offsetX - canvasOffsetX / scale + canvasOffsetX;
        rectY =
          cropShapePos.y + cy - offsetY - canvasOffsetY / scale + canvasOffsetY;
      }
      const x = oRadius ? circleX : rectX;
      const y = oRadius ? circleY : rectY;
      setShapeChange({
        params: {...shapeChange?.params, x, y},
      });
      setMaxZoomValue(
        zoomValue * minMaxZoom > minMaxZoom
          ? zoomValue * minMaxZoom
          : minMaxZoom,
      );
      setZoomValue(zoomValue);
      const rotateValueIndex =
        (object?.params?.fillPatternRotation || 0) > 0
          ? Math.floor((object?.params?.fillPatternRotation || 0) / 90)
          : 0;
      const rotateValue =
        (object?.params?.fillPatternRotation || 0) - rotateValueIndex * 90;
      setRotateValueIndex(rotateValueIndex);
      setRotateValue(rotateValue);
    };
    if (imageRef?.current?.getSize().width) {
      setImageValues(
        imageRef?.current?.getSize().width,
        imageRef?.current?.getSize().height,
      );
    } else if (imageParam) {
      imageParam.onload = function () {
        setImageValues(this.width, this.height);
      };
    }
    if (oRadius && oRadius * 2 > canvasMinSize) {
      setScale((canvasMinSize / oRadius) * 0.5);
    }
    if (!oRadius && (oWidth > canvasMinSize || oHeight > canvasMinSize)) {
      const objectMaxSize = Math.max(oWidth, oHeight);
      setScale(canvasMinSize / objectMaxSize);
    }
  };

  useEffect(() => {
    setInitialValues(scale);
  }, [scale]);

  const cropShape = (
    <object.className
      ref={cropShapeRef}
      {...object.params}
      x={cropShapePos.x}
      y={cropShapePos.y}
      width={oWidth / rectScale}
      height={oHeight / rectScale}
      radius={oRadius / radiusScale}
      fillPatternImage={null}
      image={null}
      //fill="yellow"
      opacity={0.5}
      visible={false}
      scaleX={oRadius ? radiusScale : rectScale}
      scaleY={oRadius ? radiusScale : rectScale}
      offsetX={0}
      offsetY={0}
      rotation={0}
    />
  );

  const maskShape = (
    <object.className
      {...object.params}
      width={(oWidth + strokeWidth * rectScale) / rectScale}
      height={(oHeight + strokeWidth * rectScale) / rectScale}
      radius={oRadius / radiusScale + strokeWidth / 2}
      x={maskShapePos.x}
      y={maskShapePos.y}
      fillPatternImage={null}
      image={null}
      //fill="green"
      opacity={0.5}
      fillEnabled={false}
      stroke="white"
      strokeWidth={strokeWidth}
      scaleX={oRadius ? radiusScale : rectScale}
      scaleY={oRadius ? radiusScale : rectScale}
      offsetX={0}
      offsetY={0}
      rotation={0}
    />
  );

  const image = (
    <Image
      ref={ref => {
        imageRef.current = ref;
      }}
      image={imageParam}
      {...shapeChange?.params}
      draggable
      onDragEnd={onDragEnd}
      opacity={1}
      offsetX={oRadius ? canvasOffsetX / scale : 0}
      offsetY={oRadius ? canvasOffsetY / scale : 0}
    />
  );

  const zoomContainer = (
    <S.ZoomContainer style={{marginRight: 5}}>
      <S.ZoomLabel>
        {t('Zoom')}: {parseFloat(zoomValue).toFixed(2)}
      </S.ZoomLabel>
      <S.ZoomSlider
        type="range"
        min="0.05"
        max={maxZoomValue}
        value={zoomValue}
        step="0.05"
        onChange={e => setZoomValue(Number(e?.target?.value))}
      />
    </S.ZoomContainer>
  );

  const straightenContainer = (
    <S.ZoomContainer style={{marginLeft: 5}}>
      <S.ZoomLabel>
        {t('Straighten')}: {imageRotation}
      </S.ZoomLabel>
      <S.ZoomSlider
        type="range"
        min="-45"
        max="+45"
        value={rotateValue}
        step="1"
        onChange={e => setRotateValue(Number(e?.target?.value))}
        //disabled={!oRadius}
      />
    </S.ZoomContainer>
  );

  const rotateContainer = (
    <S.RotateIcon
      onClick={() => {
        setRotateValueIndex(rotateValueIndex < 3 ? rotateValueIndex + 1 : 0);
      }}>
      <FontAwesomeIcon icon={faRotate} />
    </S.RotateIcon>
  );

  const buttonsContainer = (
    <S.Buttons>
      <ButtonWrapper onClick={() => onClose()}>
        {t('Cancel')}
      </ButtonWrapper>
      <S.ConfirmButton>
        <ButtonWrapper
          background={theme.secondaryColor}
          textColor={'white'}
          onClick={() => {
            let x = 0;
            let y = 0;
            let offsetX = 0;
            let offsetY = 0;
            if (oRadius) {
              x = canvasOffsetX + cropShapePos.x - shapeChange.params?.x;
              y = canvasOffsetY + cropShapePos.y - shapeChange.params?.y;
            } else {
              offsetX = canvasOffsetX - shapeChange.params.x;
              offsetY = canvasOffsetY - shapeChange.params.y;
              x = oWidth / 2;
              y = oHeight / 2;
            }
            const rotation = imageRotation;
            const scaleX = zoomValue;
            const scaleY = zoomValue;
            onResult({
              x,
              y,
              rotation,
              scaleX,
              scaleY,
              offsetX,
              offsetY,
            });
            onClose();
          }}>
          {t('Confirm')}
        </ButtonWrapper>
      </S.ConfirmButton>
    </S.Buttons>
  );

  return (
    <ModalWrapper
      popupRef={popupRef}
      onCancelClick={() => onClose()}
      title={t('Setup image')}
      withCross
      isScrolled={false}
      buttonsContainer={buttonsContainer}>
      <S.Root $windowMinSize={windowMinSize}>
        <Stage
          width={canvas.width}
          height={canvas.height}
          scaleX={scale}
          scaleY={scale}
          ref={stageRef}
          style={{backgroundColor: 'lightgray'}}
          x={canvasOffsetX}
          y={canvasOffsetY}
          offsetX={canvasOffsetX}
          offsetY={canvasOffsetY}>
          <Layer>
            <Group
              rotation={imageRotation}
              x={canvasOffsetX}
              y={canvasOffsetY}
              offsetX={canvasOffsetX}
              offsetY={canvasOffsetY}
              scale={{x: zoomValue, y: zoomValue}}>
              {image}
            </Group>
            <Group
              x={-canvasOffsetX / scale}
              y={-canvasOffsetY / scale}
              offsetX={-canvasOffsetX}
              offsetY={-canvasOffsetY}>
              {cropShape}
              {maskShape}
            </Group>
          </Layer>
        </Stage>
        <S.SettingsRow $windowMinSize={windowMinSize}>
          {zoomContainer}
          {straightenContainer}
          {rotateContainer}
        </S.SettingsRow>
      </S.Root>
    </ModalWrapper>
  );
};
export default SetupImage;
