import styled from 'styled-components';

export const ItemWr = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  h3 {
    width: ${props => props.theme.labelWidth};
    min-width: ${props => props.theme.labelWidth};
    font-size: ${props => props.theme.normal};
    font-weight: bold;
    color: #000;
    font-family: ${props => props.theme.mainFont};
  }
`;

export const ItemContent = styled.div`
  border-radius: 3px;
  width: 100%;
  .slider-input {
    accent-color: ${p => p.theme.primaryColor};
  }
`;
