import i18n from 'i18next';
/* eslint-disable no-console */
export const handleError = e => console.log(e);

export const getErrorMessage = errors => {
  let result;
  if (errors) {
    const values = Object.values(errors);
    result = values.map(i => i18n.t(String(i)));
  }
  return result;
};

export const showErrorNotificationV2 = (value, showErrorMessage) => {
  const {response} = value || {};
  const {errors, error} = response?.data || {};

  if (errors && showErrorMessage) {
    showErrorMessage({
      message: getErrorMessage(errors),
      isError: true,
    });
  }
  if (error && showErrorMessage) {
    showErrorMessage({
      message: i18n.t(String(error)),
      isError: true,
    });
  }
  if (showErrorMessage && !error && !errors) {
    showErrorMessage({
      message: String(value),
      isError: true,
    });
  }
};
