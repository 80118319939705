import React from 'react';
import {
  ArrowsBlock,
  ColorPicker,
  Dropdown,
  InputField,
  SelectorWithIcons,
  TextAreaField,
} from '../components';
import {getLeft, getTop} from './positionHelper';
import {unitKeys as sizeKeys} from './unitHelper';
import {ItemBlock} from '../components/itemBlock/itemBlock';
import {BgBlock} from '../layout/sidebar/styled';
import {repeatIconsData} from '../constants/personalization/repeatIconsData';
import {
  filterList,
  filtersDetailsList,
  fontList,
  fontsLineHeights,
} from './canvasHelper';
import {
  alignVariants,
  horizontalAlignMenu,
  moveMenu,
  moveVariants,
  positionMenu,
  positionVariants,
} from './alignHelper';
import {getSearchCounts} from './textInputsHelper';
import {FontStyleSelector} from '../components/fontStyleSelector/FontStyleSelector';
import * as S from '../layout/sidebar/styled';
import Konva from 'konva';
import {mirrorMenu, mirrorVariants} from './mirrorHelper';
import {FilterSelector} from '../components/filterSelector/FilterSelector';

const rotationData = [0, 90, 180, 270];

export const getPersonalizationInput = data => {
  const {
    o,
    pItem,
    settings,
    selectedUnit,
    rotationValue,
    customFonts,
    t = v => v,
    onUpdatePersonalizationParam,
    onUpdatePersonalizationParams,
    onChangeTop,
    onChangeLeft,
    onChangeWidth,
    onChangeHeight,
    setRotationValue,
    getImageContainer,
  } = data;
  switch (pItem) {
    case 'text':
      return (
        <InputField
          name="name"
          label={'Text'}
          placeholder="Enter text"
          type="textarea"
          value={o?.params?.[pItem]}
          onChange={e => onUpdatePersonalizationParam(o, pItem, e.target.value)}
          rows={
            o?.params?.text && getSearchCounts(o?.params?.[pItem], '\n') + 1
          }
        />
      );
    case 'scale':
      return (
        <ItemBlock title={t('Scale')}>
          <input
            className="slider-input"
            value={o?.params?.scaleX || 1}
            type="range"
            step={0.1}
            min={0}
            max={2}
            onChange={e => {
              const top = getTop(o, settings);
              const left = getLeft(o, settings);
              onUpdatePersonalizationParams(o, {
                scaleX: e?.target?.value,
                scaleY: e?.target?.value,
              });
              onChangeTop(o, top);
              onChangeLeft(o, left);
            }}
          />
        </ItemBlock>
        // <InputField
        //   typeTitle={'%'}
        //   outputUnit={'%'}
        //   label={t('Scale')}
        //   type="number"
        //   value={o?.params?.scaleX || 1}
        //   onChange={e => {
        //     const top = getTop(o, settings);
        //     const left = getLeft(o, settings);
        //     onUpdatePersonalizationParams(o, {
        //       scaleX: e?.target?.value,
        //       scaleY: e?.target?.value,
        //     });
        //     onChangeTop(o, top);
        //     onChangeLeft(o, left);
        //   }}
        // />
      );
    case 'width':
      return (
        <InputField
          inputUnit={sizeKeys.pixels}
          outputUnit={selectedUnit}
          value={o?.params?.width}
          label={t('Width')}
          type="number"
          typeTitle={selectedUnit}
          onChange={e => onChangeWidth(o, e.target.value)}
        />
      );
    case 'height':
      return (
        <InputField
          inputUnit={sizeKeys.pixels}
          outputUnit={selectedUnit}
          value={o?.params?.height}
          label={t('Height')}
          type="number"
          typeTitle={selectedUnit}
          onChange={e => onChangeHeight(o, e.target.value)}
        />
      );
    case 'top':
      return (
        <InputField
          value={getTop(o, settings) || 0}
          label={t('Top')}
          type="number"
          typeTitle={'%'}
          onChange={e => onChangeTop(o, e.target.value)}
        />
      );
    case 'left':
      return (
        <InputField
          value={getLeft(o, settings) || 0}
          label={t('Left')}
          type="number"
          typeTitle={'%'}
          onChange={e => onChangeLeft(o, e.target.value)}
        />
      );
    case 'fill':
      return (
        <ItemBlock
          title={t('Color')}
          content={
            <BgBlock>
              <S.ColorPickerBlock>
                <ColorPicker
                  withInput
                  color={o?.params?.fill}
                  onChange={v => onUpdatePersonalizationParam(o, pItem, v)}
                />
              </S.ColorPickerBlock>
            </BgBlock>
          }
        />
      );
    case 'background':
      return (
        <ItemBlock
          title={t('Background')}
          content={
            <BgBlock>
              <S.ColorPickerBlock>
                <ColorPicker
                  withInput
                  color={o?.params?.background}
                  onChange={v => onUpdatePersonalizationParam(o, pItem, v)}
                />
              </S.ColorPickerBlock>
            </BgBlock>
          }
        />
      );
    case 'rotation':
      return (
        <ItemBlock
          title={t('Rotation')}
          content={
            <ArrowsBlock
              label={t('Rotate')}
              onLeftClick={() => {
                const top = getTop(o, settings);
                const left = getLeft(o, settings);
                const v = rotationValue >= 1 ? rotationValue - 1 : 3;
                setRotationValue(v);
                onUpdatePersonalizationParam(o, 'rotation', rotationData[v]);
                onChangeTop(o, top);
                onChangeLeft(o, left);
              }}
              onRightClick={() => {
                const top = getTop(o, settings);
                const left = getLeft(o, settings);
                const v = rotationValue <= 3 ? rotationValue + 1 : 1;
                setRotationValue(v);
                onUpdatePersonalizationParam(o, 'rotation', rotationData[v]);
                onChangeTop(o, top);
                onChangeLeft(o, left);
              }}
            />
          }
        />
      );
    case 'repeat':
      return (
        <>
          <ItemBlock
            title={t('Repeat')}
            content={
              <SelectorWithIcons
                data={repeatIconsData}
                value={o?.params?.repeat}
                onChange={v => onUpdatePersonalizationParam(o, 'repeat', v)}
                marginsInside={10}
              />
            }
          />
          {o?.params?.repeat ? (
            <div>
              <div style={{marginTop: 10}}>
                <InputField
                  inputUnit={sizeKeys.pixels}
                  outputUnit={selectedUnit}
                  defaultValue={o?.params?.repeatOffsetX || 0}
                  label={t('RepeatOffsetX')}
                  type="number"
                  typeTitle={selectedUnit}
                  onChange={e => {
                    onUpdatePersonalizationParam(
                      o,
                      'repeatOffsetX',
                      e.target.value,
                    );
                  }}
                />
                <div style={{marginTop: 10}} />
                <InputField
                  inputUnit={sizeKeys.pixels}
                  outputUnit={selectedUnit}
                  defaultValue={o?.params?.repeatOffsetY || 0}
                  label={t('RepeatOffsetY')}
                  type="number"
                  typeTitle={selectedUnit}
                  onChange={e => {
                    onUpdatePersonalizationParam(
                      o,
                      'repeatOffsetY',
                      e.target.value,
                    );
                  }}
                />
              </div>
              <div style={{marginTop: 10}}>
                <InputField
                  inputUnit={sizeKeys.pixels}
                  outputUnit={selectedUnit}
                  defaultValue={o?.params?.repeatXMargins || 0}
                  label={t('RepeatMarginX')}
                  type="number"
                  typeTitle={selectedUnit}
                  onChange={e => {
                    onUpdatePersonalizationParam(
                      o,
                      'repeatXMargins',
                      e.target.value,
                    );
                  }}
                />
                <div style={{marginTop: 10}} />
                <InputField
                  defaultValue={o?.params?.repeatYMargins || 0}
                  label={t('RepeatMarginY')}
                  type="number"
                  typeTitle={selectedUnit}
                  onChange={e => {
                    onUpdatePersonalizationParam(
                      o,
                      'repeatYMargins',
                      e.target.value,
                    );
                  }}
                />
              </div>
            </div>
          ) : null}
        </>
      );
    case 'filters':
      return (
        <ItemBlock
          title={t('Effect')}
          content={
            <FilterSelector
              value={o?.params?.filters?.[0]}
              onChange={v => onUpdatePersonalizationParam(o, 'filters', [v])}
            />
          }
        />
        // <Dropdown
        //   label={'Filters'}
        //   data={filterList.map(
        //     v => filtersDetailsList.find(i => i?.value === v)?.name,
        //   )}
        //   onChange={v => {
        //     const filter = filtersDetailsList.find(i => i?.name === v)?.value;
        //     onUpdatePersonalizationParam(o, 'filters', [filter]);
        //   }}
        //   value={
        //     filtersDetailsList.find(i => i?.value === o?.params?.filters?.[0])
        //       ?.name
        //   }
        //   getOptionLabel={v => v}
        //   getOptionValue={v => v}
        // />
      );
    case 'fontSize':
      return (
        <InputField
          inputUnit={sizeKeys.pixels}
          outputUnit={selectedUnit}
          placeholder="0.00"
          type="number"
          label={t('Font Size')}
          typeTitle={selectedUnit}
          min={0}
          value={o.params?.fontSize || ''}
          onChange={e =>
            onUpdatePersonalizationParam(
              o,
              'fontSize',
              Number(e?.target?.value),
            )
          }
        />
      );
    case 'fontStyle':
      return (
        <ItemBlock title={t('Font Style')}>
          <FontStyleSelector
            value={o?.params?.fontStyle}
            onChange={v => onUpdatePersonalizationParam(o, 'fontStyle', v)}
          />
        </ItemBlock>
        // <Dropdown
        //   data={['normal', 'bold', 'italic', 'italic bold']}
        //   onChange={v => {
        //     onUpdatePersonalizationParam(o, 'fontStyle', v);
        //   }}
        //   value={o?.params?.fontStyle}
        //   getOptionLabel={v => v}
        //   getOptionValue={v => v}
        //   label={'Font Style'}
        // />
      );
    case 'fontFamily':
      return (
        <Dropdown
          data={[...fontList.map(i => ({name: i})), ...customFonts]}
          onChange={v => {
            //onUpdatePersonalizationParam(o, 'fontFamily', v.name);
            // lineHeight param recalculated for fonts with truncation issue (Northwell etc..)
            const prevFontLineHeight =
              fontsLineHeights.find(i => i?.name === o?.params?.fontFamily)
                ?.lineHeight || 1;
            const fontLineHeight =
              fontsLineHeights.find(i => i?.name === v.name)?.lineHeight || 1;
            const lineHeight =
              !o?.params?.lineHeight ||
              o?.params?.lineHeight === (prevFontLineHeight || 1)
                ? fontLineHeight
                : o?.params?.lineHeight;
            onUpdatePersonalizationParams(o, {
              fontFamily: v.name,
              lineHeight,
            });
          }}
          value={o?.params?.fontFamily}
          getOptionLabel={v => v.name}
          getOptionValue={v => v.name}
          label={t('Font Family')}
        />
      );
    case 'align':
      return (
        <ItemBlock
          title={t('Align')}
          content={
            <S.AlignWrapper>
              {horizontalAlignMenu.map(i => (
                <S.AlignItem
                  key={i?.id}
                  onClick={() =>
                    onChangeLeft(
                      o,
                      i?.id === alignVariants?.left
                        ? 0
                        : i?.id === alignVariants?.center
                        ? 50
                        : 100,
                    )
                  }>
                  {i?.icon}
                </S.AlignItem>
              ))}
            </S.AlignWrapper>
          }
        />
      );
    case 'visible':
      return (
        <S.BlockCheckbox>
          <InputField
            value={o?.params?.visible}
            checked={o?.params?.visible}
            label={t('Visible')}
            type="checkbox"
            className="checkbox-style"
            onChange={() => {
              onUpdatePersonalizationParam(o, 'visible', !o?.params?.visible);
            }}
          />
        </S.BlockCheckbox>
      );
    case 'fontFamilyVariants':
      return (
        <Dropdown
          data={o?.params?.[pItem] || []}
          onChange={v => {
            onUpdatePersonalizationParam(o, 'fontFamily', v);
          }}
          value={o?.params?.fontFamily}
          getOptionLabel={v => v}
          getOptionValue={v => v}
          label={t('Font Family Variant')}
        />
      );
    case 'textVariants':
      return (
        <ItemBlock title={t('Text Variants')}>
          <S.TextAreaWr>
            <Dropdown
              data={o?.params?.[pItem]}
              id="text"
              onChange={e => {
                onUpdatePersonalizationParam(o, 'text', e?.text);
              }}
              value={o?.params?.name}
              key={`o=${o?.id},param=${pItem}`}
            />
            <TextAreaField
              type="textarea"
              name="text"
              value={o?.params?.text}
              onChangeHandler={e =>
                onUpdatePersonalizationParam(o, 'text', e.target.value)
              }
              rows={
                o?.params?.text && getSearchCounts(o?.params?.text, '\n') + 1
              }
            />
          </S.TextAreaWr>
        </ItemBlock>
      );
    case 'predefinedColors':
      return (
        <ItemBlock title={t('Color variants')}>
          <S.WrappedRow>
            {o?.params?.predefinedColors.map((i, index) => (
              <S.OptionItemValueColor
                $itemsLength={o?.params?.predefinedColors.length}
                key={`o=${o?.id},param=${pItem},item=${i}`}
                color={i}
                selected={i === o?.params?.fill}
                onClick={() => {
                  o.params.image = null;
                  onUpdatePersonalizationParam(o, 'fill', i);
                }}
              />
            ))}
          </S.WrappedRow>
        </ItemBlock>
      );
    case 'predefinedImages':
      return (
        <S.WrappedRow>
          {o?.params?.predefinedImages?.map(i => (
            <S.PredefinedImageColumn
              key={`o=${o?.id},param=${pItem},item=${i?.id}`}
              onClick={() => {
                onUpdatePersonalizationParam(o, 'image', i?.image);
              }}>
              <S.ImageBackgroundWrapper>
                <img src={i?.image?.src} alt="background" />
              </S.ImageBackgroundWrapper>
            </S.PredefinedImageColumn>
          ))}
        </S.WrappedRow>
      );
    case 'position':
      return (
        <div>
          <ItemBlock title={t('Position')}>
            <div>
              <S.AlignWrapper>
                {positionMenu.slice(0, 3).map(i => (
                  <S.AlignItem
                    key={i?.id}
                    onClick={() =>
                      onChangeLeft(
                        o,
                        i?.id === positionVariants?.left
                          ? 0
                          : i?.id === positionVariants?.center
                          ? 50
                          : 100,
                      )
                    }>
                    {i?.icon}
                  </S.AlignItem>
                ))}
              </S.AlignWrapper>
              <S.AlignWrapper>
                {positionMenu.slice(3, 6).map(i => (
                  <S.AlignItem
                    key={i?.id}
                    onClick={() =>
                      onChangeTop(
                        o,
                        i?.id === positionVariants?.top
                          ? 0
                          : i?.id === positionVariants?.middle
                          ? 50
                          : 100,
                      )
                    }>
                    {i?.icon}
                  </S.AlignItem>
                ))}
              </S.AlignWrapper>
            </div>
          </ItemBlock>
        </div>
      );
    case 'move':
      return (
        <ItemBlock title={t('Move')}>
          <S.AlignWrapper>
            {moveMenu.map(i => (
              <S.AlignItem
                key={i?.id}
                onClick={() => {
                  const top = getTop(o, settings);
                  const left = getLeft(o, settings);
                  const step = 1;
                  switch (i?.id) {
                    case moveVariants.left:
                      onChangeLeft(o, left - step);
                      break;
                    case moveVariants.right:
                      onChangeLeft(o, left + step);
                      break;
                    case moveVariants.up:
                      onChangeTop(o, top - step);
                      break;
                    case moveVariants.down:
                      onChangeTop(o, top + step);
                      break;
                  }
                }}>
                {i?.icon}
              </S.AlignItem>
            ))}
          </S.AlignWrapper>
        </ItemBlock>
      );
    case 'brightness':
      return (
        <ItemBlock title={t('Brightness')}>
          <input
            className="slider-input"
            value={o?.params?.brightness}
            type="range"
            step={0.1}
            min={-1}
            max={+1}
            onChange={e => {
              const value = e.target.value;
              onUpdatePersonalizationParam(o, 'brightness', value);
              if (value !== 0) {
                if (
                  !o?.params?.filters?.length ||
                  !o?.params?.filters.includes(Konva.Filters.Brighten)
                ) {
                  onUpdatePersonalizationParam(o, 'filters', [
                    ...(o?.params?.filters || []),
                    Konva.Filters.Brighten,
                  ]);
                }
              }
            }}
          />
        </ItemBlock>
      );
    case 'mirror':
    case 'flip':
      return (
        <ItemBlock title={t('Mirror')}>
          <S.MirrorWrapper>
            {mirrorMenu.map(i => (
              <S.MirrorItem
                key={i?.id}
                onClick={() => {
                  if (i?.id === mirrorVariants.horizontal) {
                    onUpdatePersonalizationParam(
                      o,
                      'scaleX',
                      -(o?.params?.scaleX || 1),
                    );
                  }
                  if (i?.id === mirrorVariants.vertical) {
                    onUpdatePersonalizationParam(
                      o,
                      'scaleY',
                      -(o?.params?.scaleY || 1),
                    );
                  }
                }}>
                <S.MirrorImg src={i?.icon} alt={'icon'} />
              </S.MirrorItem>
            ))}
          </S.MirrorWrapper>
        </ItemBlock>
      );
    case 'image':
    case 'fillPatternImage':
      return getImageContainer({
        object: o,
        param: pItem,
      });
    default:
      return null;
  }
};
