import styled from 'styled-components';

export const Footer = styled.div`
  //min-height: ${p => (p.$isVertical ? '110px' : '70px')};
  // ${props => props.theme.screenSize.xl} {
  //   min-height: ${p => (p.$isVertical ? '102px' : '62px')};
  // }
  width: 100%;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f6f6f6;
  z-index: 10;
`;

export const CounterWr = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  gap: 10px;
  font-size: 12px;
  h3 {
    ${props => props.theme.screenSize.xl} {
      font-size: 11px;
    }
    font-weight: 500;
  }

  button {
    background-color: ${props => props.theme.primaryColor};
    border: none;
    color: #fff;
  }
  // svg {
  //   padding: 10px;
  //   ${props => props.theme.screenSize.xl} {
  //     padding: 8px;
  //   }
  //   border: #d1d1d1 1px solid;
  //   border-radius: 5px;
  //   cursor: pointer;
  // }
`;

export const AddToCart = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  //margin-right: 20px;
  button {
    border: none;
    display: inline-block;
    border-radius: 10px;
    min-height: 36px;
    padding-left: 25px;
    padding-right: 25px;
    min-width: 135px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: ${props => props.theme.semiMiddle};
    text-align: center;
    border: 0;
    color: #fff;
    background-color: ${props =>
      props?.disabled ? '#d1d1d1' : props.theme.secondaryColor};
    height: 70%;
  }
`;

export const EditModeWr = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    width: 30px;
    height: 30px;
    ${props => props.theme.screenSize.xl} {
      width: 25px;
      height: 25px;
    }
    cursor: pointer;
  }
  h3 {
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
  }
  // .rotate-icon {
  //   margin-left: 25px;
  //   ${props => props.theme.screenSize.xl} {
  //     margin-left: 20px;
  //   }
  // }
`;

export const AddAndPrevButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
  flex: 1;
  // //margin-top: ${p => (p.$isVertical ? '10px' : '0')};
  // margin-bottom: ${p => (p.$isVertical ? '10px' : '0')};
  // margin-right: ${p => (p.$isVertical ? '0' : '20px')};
`;

export const PreviewButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;
  button {
    border: 1px solid ${props => props.theme.primaryColor};
    display: inline-block;
    border-radius: 10px;
    min-height: 36px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    font-size: ${props => props.theme.semiMiddle};
    text-align: center;
    //color: ${props => props.theme.primaryColor};
    background-color: #ffffff;
    height: 70%;
  }
`;

export const CanvasButtonsArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0;
  gap: 10px;
  // height: 50px;
  // ${props => '@media(max-width:523px)'} {
  //   height: 90px;
  // }
`;

export const RotateIconWrapper = styled.div`
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid ${props => props.theme.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  .rotate-icon {
    width: 20px;
    height: 20px;
  }
  background-color: #fff;
  //padding: 7px;
`;

export const ZoomIconWrapper = styled.div`
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid ${props => props.theme.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  //padding: 7px;
`;

export const ZoomIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const ResizeIconWrapper = styled.div`
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid ${props => props.theme.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  .resize-icon {
    width: 20px;
    height: 20px;
    //margin-right: 10px;
  }
  //padding: 7px;
`;

export const IconsRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const RedoUndoWrapper = styled.div`
  margin-left: 20px;
  min-width: 70px;
  svg {
    cursor: pointer;
    padding: 5px;
    transition: 0.4s;
    font-size: 25px;
  }
  .svg-left {
    color: ${props =>
      props.$undoActive
        ? props.theme.primaryColor
        : props.theme.lightGrayColor};
    pointer-events: ${props => (props.$undoActive ? 'unset' : 'none')};
  }
  .svg-right {
    color: ${props =>
      props.$redoActive
        ? props.theme.primaryColor
        : props.theme.lightGrayColor};
    pointer-events: ${props => (props.$redoActive ? 'unset' : 'none')};
  }
`;
