import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {addBuilderCases, asyncThunkHelper} from '../utils';
import {getClientApplication} from '../../api/helpers/client';

export const fetchApplication = createAsyncThunk(
  'fetchApplication',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      default: async () => await getClientApplication(payload?.config),
    }),
);

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    application: {data: null},
  },
  reducers: {},
  extraReducers: builder => {
    addBuilderCases(builder, fetchApplication, 'application');
  },
});
