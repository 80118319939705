export const getBase64Src = v =>
  `data:image/svg+xml;utf8,${encodeURIComponent(v)}`;

export const getImageIcon = color =>
  getBase64Src(`
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="797.000000pt" height="633.000000pt" viewBox="0 0 797.000000 633.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,633.000000) scale(0.100000,-0.100000)"
fill="${color}" stroke="none">
<path d="M398 6181 c-109 -35 -197 -122 -233 -230 -13 -42 -15 -334 -13 -2801
l3 -2755 27 -58 c36 -75 112 -149 185 -179 l58 -23 3565 0 3565 0 55 26 c78
37 131 87 171 161 l34 63 3 2762 c2 2760 2 2762 -18 2816 -41 110 -126 188
-239 221 -49 14 -388 16 -3580 15 -3382 0 -3528 -1 -3583 -18z m7148 -265 l34
-34 0 -2717 0 -2717 -34 -34 -34 -34 -3526 0 -3526 0 -32 29 -33 29 0 2727 0
2727 33 29 32 29 3526 0 3526 0 34 -34z"/>
<path d="M1057 5346 c-83 -30 -146 -96 -167 -177 -7 -24 -9 -745 -8 -2025 l3
-1989 30 -53 c32 -59 87 -103 150 -121 29 -8 848 -11 2921 -11 3232 0 2945 -7
3028 76 82 82 76 -97 76 2119 0 2136 3 2023 -54 2097 -14 19 -50 48 -78 64
l-53 29 -2905 2 c-2421 2 -2911 0 -2943 -11z m5773 -1931 c0 -927 -3 -1685 -6
-1685 -3 0 -409 363 -901 807 l-896 807 -385 -389 c-277 -279 -389 -385 -398
-379 -7 5 -336 359 -730 786 l-717 778 -141 -133 c-142 -134 -1452 -1353
-1494 -1390 l-22 -20 0 1251 0 1252 2845 0 2845 0 0 -1685z m-3396 -350 l637
-689 -218 -221 c-120 -121 -375 -379 -568 -572 l-350 -353 -897 0 -898 0 0
504 0 503 822 766 c453 422 826 763 829 759 4 -4 293 -317 643 -697z m2501
-891 l895 -805 0 -70 0 -69 -1762 0 -1762 0 200 203 c110 111 498 505 863 875
365 369 666 672 668 672 2 0 406 -363 898 -806z"/>
<path d="M5818 4980 c-144 -26 -249 -81 -354 -185 -137 -136 -201 -301 -191
-490 18 -339 277 -597 615 -613 269 -12 508 132 620 375 162 352 -22 772 -390
888 -89 28 -223 39 -300 25z m161 -260 c126 -21 228 -96 285 -210 98 -198 17
-429 -184 -524 -58 -28 -75 -31 -160 -31 -86 0 -101 3 -162 32 -85 40 -151
106 -191 191 -29 60 -32 76 -31 157 0 69 6 104 23 147 44 114 172 217 292 237
30 5 56 9 59 10 3 0 34 -4 69 -9z"/>
</g>
</svg>
`);

export const getTextIcon = color =>
  getBase64Src(`
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="${color}" stroke="none">
<path d="M82 5103 c-18 -9 -43 -30 -55 -47 -22 -30 -22 -38 -25 -374 -3 -380
-2 -389 62 -435 26 -19 47 -23 134 -25 l102 -4 0 -1659 0 -1659 -90 0 c-112 0
-159 -20 -190 -80 -19 -37 -20 -58 -20 -370 0 -312 1 -333 20 -370 13 -26 34
-47 60 -60 37 -19 58 -20 370 -20 312 0 333 1 370 20 60 31 80 78 80 190 l0
90 1659 0 1659 0 4 -102 c2 -87 6 -108 25 -134 45 -62 56 -64 423 -64 367 0
378 2 423 64 22 30 22 37 25 374 3 380 2 389 -62 435 -26 19 -47 23 -133 25
l-103 4 0 1659 0 1659 90 0 c112 0 159 20 190 80 19 37 20 58 20 370 0 312 -1
333 -20 370 -13 26 -34 47 -60 60 -37 19 -58 20 -370 20 -312 0 -333 -1 -370
-20 -60 -31 -80 -78 -80 -190 l0 -90 -1659 0 -1659 0 -4 103 c-2 86 -6 107
-25 133 -45 62 -56 64 -425 64 -286 0 -338 -3 -366 -17z m4138 -673 c0 -163
47 -210 210 -210 l90 0 0 -1659 0 -1659 -102 -4 c-87 -2 -108 -6 -134 -25 -49
-35 -64 -78 -64 -182 l0 -91 -1660 0 -1660 0 0 90 c0 163 -47 210 -210 210
l-90 0 0 1660 0 1660 91 0 c104 0 147 15 182 64 19 26 23 47 25 134 l4 102
1659 0 1659 0 0 -90z"/>
<path d="M1438 4049 c-23 -12 -46 -35 -58 -59 -19 -37 -20 -58 -20 -366 0
-315 1 -329 21 -372 16 -33 32 -49 63 -63 91 -41 133 -19 311 160 l120 121
192 0 193 0 0 -882 0 -883 -137 -210 c-143 -220 -163 -258 -163 -301 0 -41 38
-99 80 -123 37 -21 47 -21 520 -21 473 0 483 0 520 21 42 24 80 82 80 123 0
43 -20 81 -163 301 l-137 210 0 883 0 882 193 0 192 0 120 -121 c178 -179 220
-201 311 -160 31 14 47 30 63 63 20 43 21 57 21 372 0 308 -1 329 -20 366 -13
26 -34 47 -60 60 -39 20 -56 20 -1122 20 -1059 -1 -1084 -1 -1120 -21z"/>
</g>
</svg>
`);

export const getZoomInIcon = color =>
  getBase64Src(`
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="139.000000pt" height="141.000000pt" viewBox="0 0 139.000000 141.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,141.000000) scale(0.100000,-0.100000)"
fill="${color}" stroke="none">
<path d="M794 1385 c-219 -48 -384 -250 -384 -470 0 -59 23 -163 46 -207 l16
-31 -236 -236 -236 -236 97 -98 98 -97 236 236 237 236 57 -21 c208 -78 456 4
575 190 182 282 22 665 -307 734 -82 18 -119 18 -199 0z m196 -191 c73 -22
159 -107 185 -183 47 -142 -15 -294 -147 -360 -51 -26 -73 -31 -133 -31 -60 0
-82 5 -133 31 -70 35 -105 71 -140 148 -21 46 -24 64 -20 130 6 94 29 142 99
205 80 72 179 93 289 60z"/>
<path d="M710 915 l0 -55 185 0 185 0 0 55 0 55 -185 0 -185 0 0 -55z"/>
</g>
</svg>
`);

export const getZoomOutIcon = color =>
  getBase64Src(`
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="143.000000pt" height="141.000000pt" viewBox="0 0 143.000000 141.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,141.000000) scale(0.100000,-0.100000)"
fill="${color}" stroke="none">
<path d="M827 1395 c-205 -56 -347 -228 -363 -438 -5 -72 11 -158 47 -245 l14
-32 -245 -245 -245 -245 95 -95 95 -95 247 247 246 246 64 -21 c188 -61 376
-17 510 119 153 157 181 396 67 584 -108 179 -337 274 -532 220z m238 -199
c73 -34 110 -69 144 -138 60 -118 36 -251 -60 -346 -77 -75 -186 -100 -299
-66 -71 21 -159 107 -183 178 -50 147 17 307 156 372 72 33 172 33 242 0z"/>
<path d="M897 1114 c-4 -4 -7 -36 -7 -71 l0 -63 -65 0 -65 0 0 -55 0 -55 65 0
65 0 0 -65 0 -65 55 0 55 0 0 65 0 65 65 0 65 0 0 55 0 55 -65 0 -64 0 -3 68
-3 67 -45 3 c-25 2 -49 0 -53 -4z"/>
</g>
</svg>
`);

export const getPatternIcon = color =>
  getBase64Src(`
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="950.000000pt" height="980.000000pt" viewBox="0 0 950.000000 980.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,980.000000) scale(0.100000,-0.100000)"
fill="${color}" stroke="none">
<path d="M6880 9787 c-15 -7 -31 -29 -39 -53 -13 -39 -12 -45 34 -184 26 -80
113 -325 192 -545 196 -539 215 -597 203 -609 -5 -5 -662 -12 -1722 -17 -1875
-8 -2235 -13 -2438 -35 -636 -67 -1225 -312 -1735 -721 -140 -112 -389 -359
-491 -487 -449 -563 -747 -1246 -828 -1896 -39 -309 -52 -893 -39 -1711 l8
-496 655 550 655 550 7 321 c8 413 28 607 83 826 224 882 953 1547 1790 1632
104 10 532 13 2099 11 1083 -1 1971 -5 1974 -7 3 -3 -58 -178 -136 -388 -144
-392 -248 -686 -297 -839 -25 -78 -27 -90 -15 -118 12 -31 54 -61 84 -61 37 0
686 522 1725 1388 708 591 841 708 841 741 0 46 -37 86 -257 273 -771 657
-2284 1888 -2319 1888 -5 0 -20 -6 -34 -13z"/>
<path d="M9215 6539 c-132 -111 -422 -355 -644 -543 -223 -187 -407 -343 -411
-346 -4 -3 -12 -174 -19 -381 -19 -615 -40 -749 -170 -1072 -103 -258 -264
-499 -476 -713 -131 -131 -213 -199 -353 -293 -184 -123 -449 -244 -602 -275
-199 -40 -240 -41 -2317 -41 -1101 0 -2004 3 -2008 6 -3 4 67 206 156 450 202
555 279 785 286 853 5 49 3 54 -22 74 -30 24 -74 28 -108 10 -32 -17 -1170
-952 -2122 -1744 -331 -276 -395 -338 -395 -387 0 -26 95 -117 345 -331 594
-511 2104 -1745 2191 -1792 55 -30 124 7 124 65 0 34 -337 992 -439 1250 -12
29 -19 56 -16 59 24 24 370 30 2580 43 905 5 1635 13 1694 19 335 32 846 213
1226 433 118 68 295 191 400 278 137 114 397 377 528 534 396 475 696 1166
798 1840 28 183 38 563 39 1458 0 591 -3 747 -12 747 -7 -1 -121 -91 -253
-201z"/>
</g>
</svg>
`);
