import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import * as S from './styled';
import {Dropdown} from '../../components';

export const Header = props => {
  const {name, templateLayers, selectedLayer, onChangeLayer} = props;

  const layerSelector = (
    <Dropdown
      data={templateLayers}
      onChange={v => onChangeLayer(v)}
      value={selectedLayer}
      getOptionLabel={v => v?.description || v?.name}
    />
  );

  return (
    <S.Header>
      <S.BackBlock
        onClick={() => window.parent.postMessage('closeButtonClicked', '*')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </S.BackBlock>
      <S.TitleWithLayerSelector>
        <S.HeaderTitle>{name || '-'}</S.HeaderTitle>
        {templateLayers?.length > 1 ? <S.LayerSelector>{layerSelector}</S.LayerSelector> : null}
      </S.TitleWithLayerSelector>
    </S.Header>
  );
};
