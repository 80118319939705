import styled from 'styled-components';

export const Root = styled.div`
  min-width: ${p => p?.$windowMinSize}px;
  padding: 20px;
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0 20px 20px 20px;
  & > div:nth-child(1) {
    button {
      background: transparent;
      //color: ${props => props.theme.primaryColor};
      border: 1px solid ${props => props.theme.primaryColor};
    }
  }
  button {
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
  }
`;

export const ConfirmButton = styled.div`
  margin-left: 15px;
`;

export const SettingsRow = styled.div`
  width: ${p => p?.$windowMinSize}px;
  display: flex;
  flex-direction: row;
`;

export const ZoomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ZoomLabel = styled.div`
  font-weight: bold;
`;

export const ZoomSlider = styled.input`
  width: 100%;
  accent-color: ${p => p.theme.primaryColor};
`;

export const RotateIcon = styled.div`
  cursor: pointer;
  align-self: center;
  margin-left: 10px;
  margin-top: 5px;
  svg {
    font-size: 22px;
    color: ${props => props.theme.primaryColor};
  }
`;
