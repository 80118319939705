import {configureStore} from '@reduxjs/toolkit';
import {settingsSlice} from './settings';
import {clientSlice} from './client';

export const store = configureStore({
  reducer: {
    settings: settingsSlice.reducer,
    client: clientSlice.reducer,
  },
});
