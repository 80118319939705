import React from 'react';

export const DisabledWidgetWarn = props => {
  const {text, onClose} = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: '#fff',
        padding: 20,
      }}>
      <div style={{textAlign: 'center'}}>{text}</div>
      <div style={{marginTop: 5}}>
        <button onClick={onClose}>{'Close'}</button>
      </div>
    </div>
  );
};
