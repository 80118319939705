import {showErrorNotificationV2} from '../helpers/error';

export const addBuilderCases = (builder: any, fetchFunc: any, name: string) => {
  builder
    .addCase(fetchFunc.pending, (state: any, action: any) => {
      state[name] = {...state[name], loading: true};
    })
    .addCase(fetchFunc.fulfilled, (state: any, action: any) => {
      state[name] = {...action?.payload, loading: false};
    });
};

export const addBuilderCasesUpdateItemInList = (
  builder: any,
  fetchFunc: any,
  name: string,
) => {
  builder
    .addCase(fetchFunc.pending, (state: any, action: any) => {
      state[name] = {...state[name], loading: true};
    })
    .addCase(fetchFunc.fulfilled, (state: any, action: any) => {
      const index = (state[name]?.data || []).findIndex(
        (i: any) => i?.id === action.payload?.data?.id,
      );
      const list = state[name].data.map((i: any) => ({...i}));
      list[index] = {...list[index], ...action.payload?.data};
      state[name] = {data: list, loading: false};
    });
};

export const asyncThunkHelper = async (
  payload: any,
  type: string,
  actions: any,
) => {
  let data: any;
  try {
    data = actions?.[type]
      ? await actions[type]()
      : actions?.default
      ? await actions?.default()
      : {};
    if (payload?.onSuccess) {
      payload.onSuccess();
    }
    if (payload?.onSuccessWithData) {
      payload.onSuccessWithData(data?.data || data);
    }
    return data;
  } catch (error) {
    console.log('error', error);
    if (payload?.onError) {
      payload.onError(error);
    }
    showErrorNotificationV2(error, payload?.showNotification);
    return {data: undefined};
  }
};
