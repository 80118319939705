import {Group, Image, Circle} from 'react-konva';
import useImage from 'use-image';
import {useTheme} from 'styled-components';
import zoomInIcon from '../../assets/icons/svg/zoom-icons-md-1.svg';
import zoomOutIcon from '../../assets/icons/svg/zoom-icons-md-2.svg';

export const CanvasZoomControl = props => {
  const {x, y, onZoomInClick, onZoomOutClick} = props;
  const theme = useTheme();
  const [zoomInSrc] = useImage(zoomInIcon);
  const [zoomOutSrc] = useImage(zoomOutIcon);
  const iconSize = 25;

  const zoomIn = (
    <Group x={0} onClick={onZoomInClick} onTap={onZoomInClick}>
      <Circle
        x={iconSize / 2}
        y={iconSize / 2}
        radius={iconSize / 1.4}
        stroke={theme.primaryColor}
        strokeWidth={1}
        fill={'#fff'}
      />
      <Image width={iconSize} height={iconSize} image={zoomInSrc} />
    </Group>
  );
  const zoomOut = (
    <Group x={iconSize + 16} onClick={onZoomOutClick} onTap={onZoomOutClick}>
      <Circle
        x={iconSize / 2}
        y={iconSize / 2}
        radius={iconSize / 1.4}
        stroke={theme.primaryColor}
        strokeWidth={1}
        fill={'#fff'}
      />
      <Image width={iconSize} height={iconSize} image={zoomOutSrc} />
    </Group>
  );

  return (
    <Group x={x} y={y}>
      {zoomIn}
      {zoomOut}
    </Group>
  );
};
