export const handleError = e => console.log(e);

export const getErrorMessage = errors => {
  let result;
  if (errors) {
    const values = Object.values(errors);
    result = values.map(i => String(i));
  }
  return result;
};

export const showErrorNotification = (
  value,
  showErrorMessage,
  errorMessage,
) => {
  const {response} = value || {};
  const {errors, error} = response?.data || {};

  if (showErrorMessage) {
    if (errors) {
      showErrorMessage({
        message: getErrorMessage(errors),
        isError: true,
      });
    } else if (error) {
      showErrorMessage({
        message: String(error),
        isError: true,
      });
    } else if (errorMessage) {
      showErrorMessage({
        message: errorMessage,
        isError: true,
      });
    }
  }
};
