import React from 'react';
import {useTranslation} from 'react-i18next';
import {filterList, filtersDetailsList} from '../../helpers/canvasHelper';
import defImage from '../../assets/icons/png/image-icon.png';
import * as S from './styled';

const allowedFilters = ['Sepia', 'Grayscale'];

export const FilterSelector = props => {
  const {value, onChange} = props;
  const {t} = useTranslation(['FilterSelector']);

  const nameList = filterList
    .map(v => filtersDetailsList.find(i => i?.value === v)?.name)
    .filter(i => allowedFilters.includes(i));

  const valueName = filtersDetailsList.find(i => i?.value === value)?.name;

  const getDisplayName = v => {
    return v === 'Grayscale' ? t('Black/White') : v;
  };

  // onChange={v => {
  //   const filter = filtersDetailsList.find(i => i?.name === v)?.value;
  //   onUpdatePersonalizationParam(o, 'filters', [filter]);
  // }}

  const listContainer = nameList.map(i => (
    <S.Item
      key={i}
      $isSelected={i === valueName}
      onClick={() => {
        const filter = filtersDetailsList.find(j => j?.name === i)?.value;
        onChange(filter);
      }}>
      <S.Icon src={defImage} alt={'icon'} />
      <S.Text>{getDisplayName(i)}</S.Text>
    </S.Item>
  ));

  return <S.Wrapper>{listContainer}</S.Wrapper>;
};
