import {Group, Rect, Text} from 'react-konva';
import {useTheme} from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import {sizeUnits, unitLabels} from '../../helpers/unitHelper';
import {setUnit} from '../../store/settings';

export const canvasRectSize = {width: 80, height: 20};

const radiusValue = 10;
export const CanvasUnitSelector = props => {
  const {x, y} = props;
  const selectedUnit = useSelector(state => state.settings.unit);
  const dispatch = useDispatch();
  const theme = useTheme();

  const unitList = sizeUnits.map((unit, index) => (
    <Group key={unit} x={(index * canvasRectSize.width) / 2}>
      <Rect
        fill={selectedUnit === unit ? theme?.primaryColor : 'lightgrey'}
        width={canvasRectSize.width / 2}
        height={canvasRectSize.height}
        cornerRadius={
          index
            ? [0, radiusValue, radiusValue, 0]
            : [radiusValue, 0, 0, radiusValue]
        }
      />
      <Text
        text={unitLabels?.[unit]}
        fontSize={canvasRectSize.height * 0.6}
        fontStyle={'bold'}
        width={canvasRectSize.width / 2}
        height={canvasRectSize.height}
        align={'center'}
        verticalAlign={'middle'}
        fill={'white'}
        onClick={() => dispatch(setUnit(unit))}
        onTap={() => dispatch(setUnit(unit))}
      />
    </Group>
  ));
  return (
    <Group x={x} y={y}>
      {unitList}
    </Group>
  );
};
